var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-form', {}, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "account-nama_lengkap"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nama Lengkap",
      "name": "nama_lengkap"
    },
    model: {
      value: _vm.profile.nama_lengkap,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "nama_lengkap", $$v);
      },
      expression: "profile.nama_lengkap"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "E-mail",
      "label-for": "account-e-mail"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.profile.email,
      "name": "email",
      "placeholder": "Email",
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No HP",
      "label-for": "account-nohp"
    }
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "value": _vm.profile.no_hp,
      "name": "nohp",
      "placeholder": "No HP"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kelamin",
      "label-for": "account-jk"
    }
  }, [_c('b-form-select', {
    attrs: {
      "name": "jk",
      "placeholder": "Jenis Kelamin",
      "options": [{
        value: 'l',
        text: 'Laki-laki'
      }, {
        value: 'p',
        text: 'Perempuan'
      }]
    },
    model: {
      value: _vm.profile.jk,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "jk", $$v);
      },
      expression: "profile.jk"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instagram",
      "label-for": "account-ig"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "ig",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "@balanjaindonesia",
            "name": "ig"
          },
          model: {
            value: _vm.profile.ig,
            callback: function callback($$v) {
              _vm.$set(_vm.profile, "ig", $$v);
            },
            expression: "profile.ig"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Whatsapp",
      "label-for": "account-wa"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Whatsapp",
      "name": "wa"
    },
    model: {
      value: _vm.profile.wa,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "wa", $$v);
      },
      expression: "profile.wa"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_c('feather-icon', {
    staticClass: "mr-1",
    attrs: {
      "icon": "MapPinIcon",
      "size": "19"
    }
  }), _vm._v("Data Alamat")], 1)])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Provinsi",
      "label-for": "v-provinsi"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function input($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.profile.provinsi_id,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "provinsi_id", $$v);
      },
      expression: "profile.provinsi_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kabupaten/Kota",
      "label-for": "v-kabko"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    on: {
      "input": function input($event) {
        return _vm.getkecamatan();
      }
    },
    model: {
      value: _vm.profile.kab_kota_id,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "kab_kota_id", $$v);
      },
      expression: "profile.kab_kota_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kecamatan",
      "label-for": "v-kecamatan"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkec
    },
    on: {
      "input": function input($event) {
        return _vm.getkelurahan();
      }
    },
    model: {
      value: _vm.profile.kecamatan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "kecamatan_id", $$v);
      },
      expression: "profile.kecamatan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kelurahan/Desa",
      "label-for": "v-kel"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkel
    },
    model: {
      value: _vm.profile.kelurahan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.profile, "kelurahan_id", $$v);
      },
      expression: "profile.kelurahan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nip",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Alamat Lengkap"
          },
          model: {
            value: _vm.profile.alamat,
            callback: function callback($$v) {
              _vm.$set(_vm.profile, "alamat", $$v);
            },
            expression: "profile.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-2 mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit2($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || 'Simpan Perubahan') + " ")])], 1)], 1)], 1)], 1), _c('foto-modal', {
    on: {
      "submitFoto": _vm.submitFoto
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }