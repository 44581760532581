<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="formKaryawan">
    <b-form class="">
      <b-row>
        <!-- <b-col cols="12" class="mb-3">
          <img v-if="myAvatarProfile" :src="myAvatarProfile" style="width: 60px;" class="d-block rounded mb-2" alt="" />
          <b-button variant="outline-primary" @click.prevent="openFotoModal">
            Ubah Foto Profile
          </b-button>
        </b-col>
        <b-col cols="12" class="mb-3">
        {{ profile }}
      </b-col> -->
        <b-col sm="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="account-nama_lengkap"
          >
            <b-form-input
              v-model="profile.nama_lengkap"
              placeholder="Nama Lengkap"
              name="nama_lengkap"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              :value="profile.email"
              name="email"
              placeholder="Email"
              disabled
            />

          </b-form-group>
        </b-col>
        
        <b-col sm="6">
          <b-form-group
            label="No HP"
            label-for="account-nohp"
          >
            <b-form-input
            disabled
              :value="profile.no_hp"
              name="nohp"
              placeholder="No HP"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Jenis Kelamin"
            label-for="account-jk"
          >
            <b-form-select 
              v-model="profile.jk"
              name="jk"
              placeholder="Jenis Kelamin"
              :options="[{value: 'l', text: 'Laki-laki'}, {value: 'p', text: 'Perempuan'}]"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Instagram"
            label-for="account-ig"
          >
          <validation-provider
                      #default="{ errors }"
                      name="ig"
                      :rules="{required: true}"
                    >
            <b-form-input
              v-model="profile.ig"
              placeholder="@balanjaindonesia"
              name="ig"
            />
            <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Whatsapp"
            label-for="account-wa"
          >
            <b-form-input
              v-model="profile.wa"
              placeholder="Whatsapp"
              name="wa"
            />
          </b-form-group>
        </b-col>
      </b-row>
              <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading"><feather-icon icon="MapPinIcon" size="19" class="mr-1" />Data Alamat</h2>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>

                <b-col md="6" sm="12">
                  <b-form-group label="Provinsi" label-for="v-provinsi">
                    <v-select v-model="profile.provinsi_id" :reduce="(option) => option.value" label="text"
                      :options="optprovinsi" @input="getkabkota()" />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Kabupaten/Kota" label-for="v-kabko">
                    <v-select v-model="profile.kab_kota_id" :reduce="(option) => option.value" label="text"
                      :options="optkabko" @input="getkecamatan()" />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Kecamatan" label-for="v-kecamatan">
                    <v-select v-model="profile.kecamatan_id" :reduce="(option) => option.value" label="text"
                      :options="optkec" @input="getkelurahan()" />
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Kelurahan/Desa" label-for="v-kel">
                    <v-select v-model="profile.kelurahan_id" :reduce="(option) => option.value" label="text"
                      :options="optkel" />
                  </b-form-group>
                </b-col>

                <!-- Alamat -->
                <b-col cols="12">
                  <b-form-group label="Alamat" label-for="v-alamat">
                    <validation-provider #default="{ errors }" name="nip" :rules="{ required: true }">
                      <b-form-textarea :state="errors.length > 0 ? false : null" v-model="profile.alamat" id="v-alamat"
                        placeholder="Alamat Lengkap" />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
                    variant="primary"
                    @click.prevent="submit2"
            class="mt-2 mr-1"
                  >
                    {{label || 'Simpan Perubahan'}}
            <!-- Simpan Perubahan -->
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
    <foto-modal @submitFoto="submitFoto" />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import {
  BFormFile, BFormTextarea,BButton, BFormSelect, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import FotoModal from './components/FotoModal.vue'

export default {
  components: {
    vSelect,
    FotoModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormSelect,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  computed: {
    myAvatarProfile() {
      return this.user.karyawan.url_foto
    },
    statusKaryawan() {
      if(this.profile.status_karyawan == 1) {
        return 'Aktif'
      }
      else {
        return 'Tidak Aktif'
      }
    },
    statusSP() {
      let status = 'Tidak memiliki SP'
      if(this.profile.status_sp == 1) {
        status = 'SP 1'
      }
      else if(this.profile.status_sp == 2) {
        status = 'SP 2'
      }
      else if(this.profile.status_sp == 3) {
        status = 'SP 3'
      }

      return status
    }
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optprovinsi: [],
      optkabko: [],
      optkec: [],
      optkel: [],
      optionSP: [{ value: 0, text: "Tidak mempunyai SP" },
        { value: 1, text: "SP 1" },
        { value: 2, text: "SP 2" },
        { value: 3, text: "SP 3" }],
      required, email, confirmed, password,
      profileFile: null,
      label: 'Simpan Perubahan',
      profile: {
        id: null,
        nama_lengkap: null,
        // tanggal_lahir: null,
        // tempat_lahir: null,
        jk: null,
        ig: null,
        wa: null,
        email: null,
        no_hp: null,
        // agama: null,
        alamat: null,
        // file_foto: null,
        provinsi_id: null,
kab_kota_id: null,
kecamatan_id: null,
kelurahan_id: null,
      },
      profile2: {}
    }
  },
  methods: {
    async submitFoto(fd) {
      for(let key in this.profile) {
        if(this.profile[key]) {
          if(key != 'email' && key != 'no_hp') {
            if(key == 'id') {
              fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
            }
            else {
              fd.append(`data[0][${key}]`, this.profile[key])
            }
          }

          
        }
      }

      try {
        this.loading = true
        const karyawan = await this.$store.dispatch('karyawan/save', fd)
        this.loading = false
        this.$bvModal.hide('foto-modal')
        this.updateProfile(karyawan)
      }
      catch(e) {
        this.loading = false
        this.$bvModal.show('foto-modal')
        this.displayError(e)
        return false
      }
    },
    openFotoModal() {
      this.$bvModal.show('foto-modal')
    },
    updateProfile(karyawan) {
      const user = JSON.parse(localStorage.userData)
      if(user.karyawan) {
        user.karyawan = this.profile

        localStorage.setItem('userData', JSON.stringify(user))
        window.location.reload()
      }
    },
    initProfile() {
      this.profile.id = this.user.id
      this.profile.karyawan_id = this.user?.karyawan?.id
      this.profile.email = this.user.email
      this.profile.no_hp = this.user.no_hp
      this.profile.nama_lengkap = this.user.karyawan.nama_lengkap
      this.profile.nip = this.user.karyawan.nip
      this.profile.nik = this.user.karyawan.nik
      this.profile.jk = this.user.karyawan.jk
      this.profile.tempat_lahir = this.user.karyawan.tempat_lahir
      this.profile.tanggal_lahir = this.user.karyawan.tanggal_lahir
      this.profile.status_sp = this.user.karyawan.status_sp
      this.profile.status_perkawinan = this.user.karyawan.status_perkawinan
      this.profile.jabatan_id = this.user.karyawan.jabatan ? this.user.karyawan.jabatan.id : null
      this.profile.jabatan = this.user.karyawan.jabatan ? this.user.karyawan.jabatan.nama_jabatan : null
      this.profile.grade_karyawan = this.user.karyawan.grade_karyawan
      this.profile.masa_kerja = this.user.karyawan.masa_kerja
      this.profile.kualifikasi_pendidikan = this.user.karyawan.kualifikasi_pendidikan
      this.profile.pendidikan_terakhir = this.user.karyawan.pendidikan_terakhir
      this.profile.kampus_sekolah = this.user.karyawan.kampus_sekolah
      this.profile.alamat = this.user.karyawan.alamat
      this.profile.agama = this.user.karyawan.agama
    },
    resetForm() {
      this.profile = JSON.parse(localStorage.getItem('userData'))
    },
    submit() {
      
      this.$refs.formKaryawan.validate().then(success => {
        if(success) {
          const fd = new FormData()
          for(let key in this.profile) {
            if(this.profile[key]) {
              if(key == 'id') {
                fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
              }
              else {
                fd.append(`data[0][${key}]`, this.profile[key])
              }
            }
          }
          this.label = 'Loading...'
          this.$store.dispatch('karyawan/save', fd)
          .then(karyawan => {
            this.label = 'Simpan Perubahan'
            this.displaySuccess({
              message: 'Profile berhasil diperbaharui'
            })
            this.updateProfile(karyawan)
          })
          .catch(e => {
            this.label = 'Simpan Perubahan'
            this.displayError(e)
            return false
          })
        }
      })
    },
    submit2() {
      this.$refs.formKaryawan.validate().then(success => {
        if(success) {
          // const fd = new FormData()
          // for(let key in this.profile) {
          //   if(this.profile[key]) {
          //     if(key == 'id') {
          //       fd.append(`data[0][${key}]`, this.profile['karyawan_id'])
          //     }
          //     else {
          //       fd.append(`data[0][${key}]`, this.profile[key])
          //     }
          //   }
          // }
          let fd = this.profile
          fd.member_id = this.user.member_id
          this.label = 'Loading...'
          this.$store.dispatch('member/updatemember', fd)
          .then(karyawan => {
            this.label = 'Simpan Perubahan'
            this.displaySuccess({
              message: 'Profile berhasil diperbaharui'
            })
            this.updateProfile(karyawan)
          })
          .catch(e => {
            this.label = 'Simpan Perubahan'
            this.displayError(e)
            return false
          })
        }
      })
    },
    async getProvinsi() {
      let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
      provinsi = JSON.parse(JSON.stringify(provinsi));
      let newprovinsi = [];
      provinsi.map((item) => {
        newprovinsi.push({
          text: item.name,
          value: item.id,
        });
      });
      // this.id_provinsi = newprovinsi;
      this.optprovinsi = newprovinsi;
      // });
    },
    async getkabkota() {
      const params = {
        id_provinsi: this.profile.provinsi_id ? this.profile.provinsi_id : null
      }
      let kabko = await this.$store.dispatch("wilayah/getkabko", params);
      kabko = JSON.parse(JSON.stringify(kabko));
      let newkabko = [];
      kabko.map((item) => {
        newkabko.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkabko = newkabko;
    },
    async getkecamatan() {
      const params = {
        id_kab_kota: this.profile.kab_kota_id ? this.profile.kab_kota_id : null
      }
      let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
      kec = JSON.parse(JSON.stringify(kec));
      let newkec = [];
      kec.map((item) => {
        newkec.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkec = newkec;
    },
    async getkelurahan() {
      const params = {
        id_kecamatan: this.profile.kecamatan_id
      }
      let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
      kel = JSON.parse(JSON.stringify(kel));
      let newkel = [];
      kel.map((item) => {
        newkel.push({
          text: item.name,
          value: item.id,
        });
      });
      this.optkel = newkel;
    },

    async getData() {
      // if (this.user.member_id){
        let member = await this.$store.dispatch("member/getDataById",this.user.member_id);
        this.profile = member;
    this.getProvinsi()
    this.getkabkota()
this.getkecamatan()
this.getkelurahan()
//       } else {
//         let karyawan = awaitthis.$store.dispatch("karyawan/getDataById",this.user.karyawan.id);
//         this.profile = karyawan;
//     this.getProvinsi()
//     this.getkabkota()
// this.getkecamatan()
// this.getkelurahan()
      // }
        // console.log("member", member);
        // }
        // var newVarian = [];
        // member.varian_item.map((item) => {
        //   newVarian.push({
        //     member_id: item.member_id,
        //     kode: item.kode,
        //     varian: item.varian,
        //     harga_dasar: item.harga_dasar,
        //     no_batch: item.no_batch,
        //     expire_date: item.expire_date,
        //     text: item.varian,
        //     value: item.member_id,
        //   });
        // });
    },
  },
  async mounted() {
    // this.initProfile()
    this.getData()
    
  }
}
</script>
